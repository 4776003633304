<template>
  <v-dialog 
    v-model="value"
    :max-width="size"
    transition="slide-y-reverse-transition"
    :fullscreen="breakpoint(null, 'xs')"
    :persistent="loading"
  >
    <v-sheet 
      class="flashmob card-content d-flex flex-column"
      :height="breakpoint(null, 'xs') ? '100vh' : '90vh'"
    >
      <v-progress-linear
        v-if="loading"
        indeterminate
        absolute
        size="3"
        color="primary"
      />
      <v-fab-transition>
        <v-btn
          text
          icon
          :absolute="breakpoint('xs')"
          :fixed="breakpoint(null, 'xs')"
          fab
          small
          class="close-btn elevation-2"
          @click="close"
        >
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-card-title class="pr-16">
        {{ title }}
      </v-card-title>

      <v-divider />

      <v-window
        v-model="level"
      >
        <v-window-item>
          <v-fade-transition>
            <v-card-text 
              v-if="value"
              class="scrollable py-6"
              :style="{ 'max-height': `calc(${breakpoint(null, 'xs') ? '100vh' : '90vh'} - 132px)` }"
            >
              <m-field
                :label="$t(fields.type.label)"
                light
                color="primary"
                class="control"
              >
                <div 
                  class="d-flex align-center"
                >
                  <v-select
                    v-show="toggle.type"
                    v-model="fields.type.value"
                    :items="fields.type.items"
                    :menu-props="['auto']"
                    mandatory
                    solo
                    flat
                    hide-details
                  />
                  <v-btn 
                    v-show="toggle.hoload"
                    depressed
                    :text="!fields.hoload.value"
                    :color="fields.hoload.value ? 'primary' : 'grey'"
                    class="px-3"
                    @click="fields.hoload.value = fields.hoload.value ? 0 : 1"
                  >
                    <v-icon
                      v-for="(l, i) in $t(fields.hoload.label).toLowerCase().split('')"
                      :key="l+i"
                      :style="{ 'margin': '0 -6px' }"
                    >
                      {{ icons[l] }}
                    </v-icon>
                  </v-btn>
                  <v-switch
                    v-show="toggle.active"
                    v-model="fields.active.value"
                    :false-value="0"
                    :true-value="1"
                    color="success"
                    inset
                    hide-details
                    class="my-0 py-0 ml-4"
                  />
                </div>
              </m-field>
              <m-field
                v-show="toggle.title"
                :label="$t(fields.title.label)"
                light
                color="primary"
                class="control"
              >
                <div 
                  class="d-flex fill-width"
                >
                  <v-text-field 
                    v-model="fields.title.value"
                    :label="$t(fields.title.label)"
                    :disabled="loading||disabled||fields.title.disabled"
                    :loading="fields.title.loading"
                    :rules="fields.title.rules"
                    solo
                    flat
                    hide-details
                    class="combo-control"
                  />
                  <v-text-field 
                    v-model="fields.max.value"
                    :disabled="loading||disabled||fields.max.disabled"
                    :loading="fields.max.loading"
                    :prepend-inner-icon="icons.person"
                    :placeholder="$t(fields.max.placeholder)"
                    type="number"
                    solo
                    flat
                    hide-details
                    class="combo-control"
                    :style="{ 'max-width': '100px' }"
                  />
                </div>
              </m-field>
              <v-text-field 
                v-show="toggle.reward"
                v-model="fields.reward.value"
                :label="$t(fields.reward.label)"
                prefix="R$"
                :disabled="loading||disabled||fields.reward.disabled"
                :loading="fields.reward.loading"
                :rules="fields.reward.rules"
                outlined
                hide-details
                class="control"
              />
              <m-field
                v-show="toggle.mobilizesAt"
                :label="$t(fields.mobilizesAt.label)"
                light
                color="primary"
                class="control"
              >
                <div 
                  :class="{ 'flex-column': breakpoint(null, 'xs') }"
                  class="d-flex"
                >
                  <div 
                    class="d-flex align-center fill-width"
                  >
                    <v-menu
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.mobilizesAt.date"
                          :disabled="loading||disabled||fields.mobilizesAt.disabled"
                          :loading="fields.mobilizesAt.loading"
                          :rules="fields.mobilizesAt.rules"
                          type="date"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('mobilizesAt', 'date', value)"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates.mobilizesAt.date"
                        scrollable
                        @input="value => updateDate('mobilizesAt', 'date', value)"
                      />
                    </v-menu>
                    <v-menu
                      transition="scale-transition"
                      :close-on-content-click="false"
                      ref="menu-mobilizesAt-time"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.mobilizesAt.time"
                          :disabled="loading||disabled||fields.mobilizesAt.disabled"
                          :loading="fields.mobilizesAt.loading"
                          :rules="fields.mobilizesAt.rules"
                          type="time"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('mobilizesAt', 'time', value)"
                        />
                      </template>
                      <v-time-picker
                        v-model="dates.mobilizesAt.time"
                        format="24hr"
                        @click:minute="$refs['menu-mobilizesAt-time'].save(dates.mobilizesAt.time)"
                        @input="value => updateDate('mobilizesAt', 'time', value)"
                      />
                    </v-menu>
                  </div>
                  <v-divider 
                    :vertical="breakpoint('xs')"
                    class="mx-2" 
                  />
                  <div 
                    class="d-flex align-center fill-width"
                  >
                    <v-menu
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.demobilizesAt.date"
                          :disabled="loading||disabled||fields.demobilizesAt.disabled"
                          :loading="fields.demobilizesAt.loading"
                          :rules="fields.demobilizesAt.rules"
                          type="date"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('demobilizesAt', 'date', value)"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates.demobilizesAt.date"
                        scrollable
                        @input="value => updateDate('demobilizesAt', 'date', value)"
                      />
                    </v-menu>
                    <v-menu
                      transition="scale-transition"
                      :close-on-content-click="false"
                      ref="menu-demobilizesAt-time"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.demobilizesAt.time"
                          :disabled="loading||disabled||fields.demobilizesAt.disabled"
                          :loading="fields.demobilizesAt.loading"
                          :rules="fields.demobilizesAt.rules"
                          type="time"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('demobilizesAt', 'time', value)"
                        />
                      </template>
                      <v-time-picker
                        v-model="dates.mobilizesAt.time"
                        format="24hr"
                        @click:minute="$refs['menu-demobilizesAt-time'].save(dates.demobilizesAt.time)"
                        @input="value => updateDate('demobilizesAt', 'time', value)"
                      />
                    </v-menu>
                  </div>
                </div>
              </m-field>
              <m-field
                v-show="toggle.startsAt"
                :label="$t(fields.startsAt.label)"
                light
                color="primary"
                class="control"
              >
                <div 
                  :class="{ 'flex-column': breakpoint(null, 'xs') }"
                  class="d-flex fill-width"
                >
                  <div 
                    class="d-flex align-center fill-width"
                  >
                    <v-menu
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.startsAt.date"
                          :disabled="loading||disabled||fields.startsAt.disabled"
                          :loading="fields.startsAt.loading"
                          :rules="fields.startsAt.rules"
                          type="date"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('startsAt', 'date', value)"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates.startsAt.date"
                        scrollable
                        @input="value => updateDate('startsAt', 'date', value)"
                      />
                    </v-menu>
                    <v-menu
                      transition="scale-transition"
                      :close-on-content-click="false"
                      ref="menu-startsAt-time"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.startsAt.time"
                          :disabled="loading||disabled||fields.startsAt.disabled"
                          :loading="fields.startsAt.loading"
                          :rules="fields.startsAt.rules"
                          type="time"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('startsAt', 'time', value)"
                        />
                      </template>
                      <v-time-picker
                        v-model="dates.startsAt.time"
                        format="24hr"
                        @click:minute="$refs['menu-startsAt-time'].save(dates.startsAt.time)"
                        @input="value => updateDate('startsAt', 'time', value)"
                      />
                    </v-menu>
                  </div>
                  <v-divider 
                    :vertical="breakpoint('xs')"
                    class="mx-2" 
                  />
                  <div 
                    class="d-flex align-center fill-width"
                  >
                    <v-menu
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.endsAt.date"
                          :disabled="loading||disabled||fields.endsAt.disabled"
                          :loading="fields.endsAt.loading"
                          :rules="fields.endsAt.rules"
                          type="date"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('endsAt', 'date', value)"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates.endsAt.date"
                        scrollable
                        @input="value => updateDate('endsAt', 'date', value)"
                      />
                    </v-menu>
                    <v-menu
                      transition="scale-transition"
                      :close-on-content-click="false"
                      ref="menu-endsAt-time"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="dates.endsAt.time"
                          :disabled="loading||disabled||fields.endsAt.disabled"
                          :loading="fields.endsAt.loading"
                          :rules="fields.endsAt.rules"
                          type="time"
                          solo
                          flat
                          hide-details
                          class="combo-control"
                          v-bind="attrs"
                          v-on="on"
                          @input="value => updateDate('endsAt', 'time', value)"
                        />
                      </template>
                      <v-time-picker
                        v-model="dates.endsAt.time"
                        format="24hr"
                        @click:minute="$refs['menu-endsAt-time'].save(dates.endsAt.time)"
                        @input="value => updateDate('endsAt', 'time', value)"
                      />
                    </v-menu>
                  </div>
                </div>
              </m-field>
              <v-text-field 
                v-show="toggle.location"
                v-model="fields.location.value"
                :label="$t(fields.location.label)"
                :disabled="loading||disabled||fields.location.disabled"
                :loading="fields.location.loading"
                :rules="fields.location.rules"
                outlined
                hide-details
                class="control"
              />
              <v-text-field 
                v-show="toggle.meeting_point"
                v-model="fields.meeting_point.value"
                :label="$t(fields.meeting_point.label)"
                :disabled="loading||disabled||fields.meeting_point.disabled"
                :loading="fields.meeting_point.loading"
                :rules="fields.meeting_point.rules"
                outlined
                hide-details
                class="control"
              />
              <v-textarea
                v-show="toggle.obs"
                v-model="fields.obs.value"
                :label="$t(fields.obs.label)"
                :disabled="loading||disabled||fields.obs.disabled"
                :loading="fields.obs.loading"
                :rules="fields.obs.rules"
                rows="2"
                auto-grow
                outlined
                hide-details
                class="control"
              />
              <v-text-field 
                v-show="toggle.route"
                v-model="fields.route.value"
                :label="$t(fields.route.label)"
                :disabled="loading||disabled||fields.route.disabled"
                :loading="fields.route.loading"
                :rules="fields.route.rules"
                outlined
                hide-details
                class="control"
              />
              <m-field
                v-show="!isDraft&&toggle.drivers"
                :label="driversFieldLabel"
                :loading="loading"
                light
                color="primary"
                class="control"
              >
                <driver-list
                  :list="driversStatus"
                  :order="fields.drivers.order"
                  flat
                  width="100%"
                  max-height="auto"
                  class="snap-child"
                  @get-profile="openDriver"
                >
                  <template v-slot:header>
                    <div class="list-header pr-4 my-1 d-flex align-center">
                      <v-autocomplete
                        v-model="fields.drivers.search.value"
                        :search-input="fields.drivers.search.query"
                        :placeholder="$t(fields.drivers.search.label)"
                        :items="driversList"
                        :filter="driverFilter"
                        item-text="fullname"
                        item-value="id"
                        return-object
                        solo
                        flat
                        hide-no-data
                        hide-details
                        @input="driver => selectDriver(true, driver)"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.fullname }}</v-list-item-title>
                            <v-list-item-subtitle>{{ data.item.vehicle.brand + ' ' + data.item.vehicle.model + ' ' + data.item.vehicle.color }} | Buzzer #{{ data.item.buzzer.number }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>

                      <m-btn-long
                        :icon="icons.send"
                        press-color="primary"
                        :disabled="loading||changed||disabled"
                        :tooltip="{ top: true, text: 'Enviar convite a todos' }"
                        :duration="2000"
                        @press="invite"
                      />
                    </div>
                  </template>
                  <template v-slot:item-content="{ driver }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ driver.fullname }}
                      </v-list-item-title>
                      <v-list-item-subtitle 
                        v-html="driver.flashmobStatus"
                      />
                    </v-list-item-content>
                  </template>
                  <template 
                    v-slot:item-actions="{ driver }"
                  >
                    <v-list-item-action
                      v-if="actions.invite&&driver.id_flashmob_driver!=null&&(driver.invitedAt==null||driver.uninvitedAt!=null)"
                      class="ml-0"
                    >
                      <m-btn-long
                        :icon="icons.send"
                        press-color="primary"
                        :disabled="loading||disabled"
                        :tooltip="{ top: true, text: $t('flashmobs.send_invite') }"
                        :duration="1000"
                        @press="invite(driver.partner_id)"
                      />
                    </v-list-item-action>
                    <v-list-item-action
                      v-if="actions.confirm&&driver.id_flashmob_driver!=null&&driver.acceptedAt==null"
                      class="ml-0"
                    >
                      <m-btn-long
                        :icon="icons.check"
                        press-color="primary"
                        :disabled="loading||disabled"
                        :tooltip="{ top: true, text: 'flashmobs.confirm_presence' }"
                        :duration="1000"
                        @press="rsvp(driver.partner_id)"
                      />
                    </v-list-item-action>
                    <v-list-item-action
                      v-if="actions.remove&&driver.uninvitedAt==null"
                      class="ml-0"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="selectDriver(false, driver)"
                          >
                            <v-icon size="20">
                              {{ icons.close }}
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ $t(driver.invitedAt!=null ? 'general.uninvite' : 'general.remove') }}
                      </v-tooltip>
                    </v-list-item-action>
                  </template>
                  <template 
                    v-slot:actions="{ selected }"
                  >
                    <v-list>
                      <v-list-item
                        v-show="actions.invite"
                        @click="invite(selected)"
                      >
                        <v-list-item-title>{{ $t('general.invite') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="message(true, selected)"
                      >
                        <v-list-item-title>{{ $t('drivers.messenger.title') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="map(selected)"
                      >
                        <v-list-item-title>{{ $t('flashmobs.see_in_map') }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </template>
                </driver-list>
              </m-field>
              <m-player-config
                v-show="!isDraft&&toggle.player_config"
                v-model="fields.player_config.value"
                :drivers="driversStatus"
                :label="$t(fields.player_config.label)"
                :disabled="loading||disabled||fields.player_config.disabled"
                :loading="fields.player_config.loading"
                :time-min="fields.mobilizesAt.value"
                :time-max="fields.demobilizesAt.value"
                :icons="icons"
                light
                outlined
                class="control mb-8"
                @change="(value) => setField('player_config', value)"
              />
              <m-field
                v-show="!isDraft&&toggle.program"
                v-model="fields.program.value"
                :label="$t(fields.program.label)"
                :disabled="loading||disabled||fields.program.disabled"
                :loading="fields.program.loading"
                light
                outlined
                class="control mb-6"
                @click="edit('program')"
              >
                <m-ads
                  v-if="!!fields.program.value"
                  :ads="fields.program.value.ads"
                  class="ma-3"
                />
                <div 
                  v-else
                  class="text-subtitle-1 text--secondary pa-3"
                >
                  {{ $t('flashmobs.unprogrammed') }}
                </div>
                <v-btn
                  fab
                  small
                  absolute
                  bottom
                  right
                  class="mb-7"
                  @click="edit('program')"
                >
                  <v-icon>
                    {{ icons.edit }}
                  </v-icon>
                </v-btn>
              </m-field>
              <div
                v-if="!isDraft&&fields.type.value!='FIL'"
                class="mb-4 d-flex"
              >
                <m-btn-long
                  text
                  :icon="icons.check"
                  press-color="primary"
                  :loading="loading"
                  :disabled="loading||disabled"
                  :label="$t('flashmobs.rewards_settled')"
                  :duration="2000"
                  @press="payment"
                />
              </div>
            </v-card-text>
          </v-fade-transition>
        </v-window-item>
        <v-window-item>
          <v-card-text
            class="py-6"
          >
            <m-field
              v-model="fields.program.value"
              :label="$t(fields.program.label)"
              :disabled="loading||disabled||fields.program.disabled"
              :loading="fields.program.loading"
              light
              outlined
              class="mb-4"
            >
              <m-program
                :value="fields.program.value"
                :start="fields.mobilizesAt.value"
                :end="fields.demobilizesAt.value"
                :user="user"
                max-height="60vh"
                class=""
                @alert="alert"
                @change="(value, save) => setField('program', value, save)"
              />
            </m-field>
          </v-card-text>
        </v-window-item>
      </v-window>
      <v-divider />
      <v-card-actions class="pa-4 pl-2">
        <v-btn
          v-if="level"
          text
          color="grey"
          @click="edit(null)"
        >
          {{ $t('general.back') }}
        </v-btn>
        <m-btn-long
          v-else-if="!isDraft"
          :icon="icons.remove"
          press-color="error"
          :disabled="loading||disabled"
          :tooltip="{ right: true, text: $t('general.remove') }"
          :duration="2000"
          @press="cancel"
        />
        <v-spacer />
        <v-btn
          v-if="level==0"
          depressed
          color="primary darken-1"
          :disabled="!changed||loading||!validated||disabled"
          :loading="loading"
          class="px-4"
          @click="save"
        >
          {{ $t('general.'+(!changed ? 'saved' : data===null||data.id=='new' ? 'create' : 'save')) }}
        </v-btn>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<style>

  .flashmob {
    position: relative;
  }
  .flashmob .control {
    margin-bottom: 24px;
  }
  .flashmob .control input[type="date"]::-webkit-inner-spin-button,
  .flashmob .control input[type="date"]::-webkit-calendar-picker-indicator,
  .flashmob .control input[type="time"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }

  .flashmob .close-btn {
    top: .75rem;
    right: .75rem;
  }
</style>

<script>

  import services from '@/services'
  import { mdiCheck, mdiClose, mdiCog, mdiSend, mdiTrashCanOutline, mdiAccount, mdiAlphaH, mdiAlphaO, mdiAlphaL, mdiClockOutline, mdiBrightness5, mdiCircleHalfFull, mdiPlus } from '@mdi/js';
  
  export default {
    name: 'Flashmob',

    data: () => ({
      icons: {
        close: mdiClose,
        send: mdiSend,
        check: mdiCheck,
        edit: mdiCog,
        plus: mdiPlus,
        remove: mdiTrashCanOutline,
        person: mdiAccount,
        clock: mdiClockOutline,
        brightness: mdiBrightness5,
        contrast: mdiCircleHalfFull,
        h: mdiAlphaH,
        o: mdiAlphaO, 
        l: mdiAlphaL,
      },
      fields: {
        title: {
          label: 'general.name',
          value: null,
          disabled: false,
          loading: false,
          rules: [v => !!v && v!='' || 'Campo obrigatório']
        },
        max: {
          label: 'flashmobs.max_participants',
          placeholder: 'general.max',
          value: null,
          disabled: false,
          loading: false,
        },
        type: {
          label: 'flashmobs.type',
          value: 'FLS',
          items: [
            {
              text: 'Flashmob',
              value: 'FLS',
            },
            {
              text: 'Fila',
              value: 'FIL',
            },
            {
              text: 'Swarm',
              value: 'SWR',
            }
          ],
          disabled: false,
          loading: false,
        },
        hoload: {
          label: 'flashmobs.holo',
          value: false,
          hidden: ['type:FIL'],
          disabled: false,
          loading: false,
        },
        active: {
          label: 'general.active',
          value: false,
          disabled: false,
          loading: false,
        },
        reward: {
          label: 'flashmobs.reward',
          value: null,
          hidden: ['type:FIL'],
          disabled: false,
          loading: false,
        },
        mobilizesAt: {
          label: 'flashmobs.mobilization_date',
          value: null,
          date: true,
          order: 0,
          hidden: ['type:FIL'],
          disabled: false,
          loading: false,
        },
        demobilizesAt: {
          label: 'flashmobs.mobilization_date',
          value: null,
          date: true,
          order: 3,
          hidden: ['type:FIL'],
          disabled: false,
          loading: false,
        },
        startsAt: {
          label: 'flashmobs.start_date',
          value: null,
          date: true,
          order: 1,
          disabled: false,
          loading: false,
        },
        endsAt: {
          label: 'flashmobs.start_date',
          value: null,
          date: true,
          order: 2,
          disabled: false,
          loading: false,
        },
        location: {
          label: 'flashmobs.location',
          value: null,
          hidden: ['type:FIL'],
          disabled: false,
          loading: false,
        },
        meeting_point: {
          label: 'flashmobs.meeting_point',
          value: null,
          hidden: ['type:FIL'],
          disabled: false,
          loading: false,
        },
        obs: {
          label: 'flashmobs.observations',
          value: null,
          disabled: false,
          loading: false,
        },
        drivers: {
          label: 'flashmobs.partners',
          value: null,
          search: {
            query: null,
            value: null,
            label: 'general.search'
          },
          actions: {
            select: {},
            invite: {
              hidden: ['type:FIL'],
            },
            confirm: {
              hidden: ['type:FIL'],
            },
            remove: {}
          },
          order: [[s => s.uninvitedAt!=null ? 0 : s.declinedAt!=null ? 1 : s.id_flashmob_driver==null ? 4 : s.acceptedAt!=null ? 2 : 3, 'fullname'], ['desc', 'asc']],
          disabled: false,
          loading: false,
        },
        route: {
          label: 'flashmobs.route',
          value: null,
          hidden: ['type:FIL'],
          disabled: false,
          loading: false,
          hint: 'Link Google Maps ou descrição (ex: 4 voltas em torno do Shopping Tijuca)'
        },
        player_config: {
          toggle: true,
          value: [],
          label: 'flashmobs.player_config',
          disabled: false,
          loading: false,
        },
        program: {
          toggle: true,
          label: 'flashmobs.program',
          hint: 'flashmobs.program_hint',
          value: null,
          disabled: false,
          loading: false,
        },
      },
      level: 0
    }),
    
    computed: {
      isDraft () {
        const data = this.data;
        return data==null||data.id=='new';
      },

      title () {
        const data = this.data;
        const fields = this.fields;
        const title = fields.title;
        const type = _.find(this.fields.type.items, ['value', this.fields.type.value]);
        const level = this.level;
        const editing = level>0 ? _.find(fields, ['editing', true]) : false;
        return data==null||(data.id=='new'&&title.value===null) ? 'Criar '+type.text : type.text + ' ' + title.value + (editing ? ' > ' + this.$t(editing.label) : '');
      },

      briefing () {
        const data = _.clone(this.data);
        const startsAt = this.$moment(data.mobilizesAt).isValid() ? this.$moment(data.mobilizesAt).format('DD/MM HH:mm') : 'A definir';
        const duration = this.$moment(data.mobilizesAt).isValid() && this.$moment(data.endsAt).isValid() ? this.$moment.duration(this.$moment(data.endsAt).diff(data.mobilizesAt)).humanize() : 'A definir';
        let briefing = 'Dia/Horário: '+startsAt;
        briefing += '\nDuração: '+duration;
        if (!!data.location) briefing += '\nLocal: '+data.location;
        if (!!data.meeting_point) briefing += '\nPonto de Encontro: '+data.meeting_point;
        if (!!data.obs) briefing += '\nObs: '+data.obs;
        // if (!!data.route) briefing += '\nTrajeto: '+data.route;
        if (!!data.reward) briefing += '\nRemuneração: R$ '+data.reward;
        return briefing;
      },

      dates () {
        const dates = ['mobilizesAt', 'demobilizesAt', 'startsAt', 'endsAt'];
        const fields = this.fields;
        return _.mapValues(_.keyBy(dates), key => {
          const datetime = this.$moment(fields[key].value);
          return {
            date: datetime.isValid() ? datetime.format('YYYY-MM-DD') : null,
            time: datetime.isValid() ? datetime.format('HH:mm') : null,
          }
        })
      },

      driversFieldLabel () {
        const status = this.driversStatus;
        console.log(status);
        
        const count = status.length > 0 ? _.countBy(status, driver => {
          const uninvited = driver.uninvitedAt!=null&&(driver.invitedAt==null||this.$moment(driver.uninvitedAt).isAfter(driver.invitedAt));
          const accepted = driver.acceptedAt!=null&&(driver.declinedAt==null||this.$moment(driver.acceptedAt).isAfter(driver.declinedAt));
          const declined = driver.declinedAt!=null&&(driver.acceptedAt==null||this.$moment(driver.acceptedAt).isBefore(driver.declinedAt));
          return uninvited||declined ? 'x' : accepted ? 'ok' : 'pending';
        }) : {};
        const { x=0, pending=0, ok=0 } = count;
        const label = this.$t(this.fields.drivers.label) + ` (${ok}/${ok+pending})`;
        return label;
      },

      driversList () {
        return _.values(this.drivers);
      },

      driversStatus () {
        const selected = _.cloneDeep(this.fields.drivers.value);
        const drivers = this.drivers;
        const program = _.isNil(this.data) ? null : this.data.program;
        const program_key = _.isNil(program) ? null : program.program_id+'-'+this.slugify(this.data.title);

        return _.map(_.clone(selected), status => {
          const driver = _.has(drivers, status.id) ? drivers[status.id] : {}
          status = _.merge(_.cloneDeep(this.driverPrototype), driver, status);
          const buzzer = _.has(driver, 'buzzer') ? driver.buzzer.number : '';
          const vehicle = _.has(driver, 'vehicle') ? driver.vehicle : null;
          const selected = status.id_flashmob_driver!=null;
          const invited = status.invitedAt!=null&&status.uninvitedAt==null;
          const uninvited = status.uninvitedAt!=null&&(status.invitedAt==null||this.$moment(status.uninvitedAt).isAfter(status.invitedAt));
          const accepted = status.acceptedAt!=null&&(status.declinedAt==null||this.$moment(status.acceptedAt).isAfter(status.declinedAt));
          const declined = status.declinedAt!=null&&(status.acceptedAt==null||this.$moment(status.acceptedAt).isBefore(status.declinedAt));
          const swarm = _.isNil(program) ? null : _.find(_.split(status.swarm, '^'), (swarm) => {
            return swarm.indexOf(program_key)>=0;
          });
          let flashmobStatus = !selected ? '' : (
            uninvited ? this.$t('flashmobs.uninvited') : 
            declined ? this.$t('invite_declined') : 
            accepted ? this.statusBuzzer(status) + ' #'+_.padStart(buzzer, 3, '0') + (_.isNil(program) ? '' : ' | Fila ' + (_.isNil(swarm) ? '🕒' : swarm.indexOf('$$ERRO')>=0 ? '🛑' : '✅')) :
            !invited ? this.$t('invite_pending') : this.$t('invite_sent')+' '+this.$moment(status.invitedAt).fromNow())
          if (!!vehicle) flashmobStatus += ` | <b>${vehicle.model} ${vehicle.color}</b>`;
          return { 
            ...status, 
            flashmobStatus
          };
        });
      },

      toggle () {
        const fields = _.cloneDeep(this.fields);
        const toggle = _.reduce(fields, (state, field, key) => {
          state[key] = !_.has(field, 'hidden') || !_.some(field.hidden, condition => {
            const [k, v] = _.split(condition, ':');
            return fields[k].value==v;
          });
          return state;
        }, {});
        return toggle;
      },

      actions () {
        const fields = this.fields;
        const actions = this.fields.drivers.actions;
        const toggle = _.reduce(actions, (state, action, key) => {
          state[key] = !_.has(action, 'hidden') || !_.some(action.hidden, condition => {
            const [k, v] = _.split(condition, ':');
            return fields[k].value==v;
          });
          return state;
        }, {});
        return toggle;
      },

      changed () {
        const data = this.data;
        const fields = this.fields;
        return !!data&&_.some(fields, (field, f) => !_.isEqual(field.value, data[f]));
      },

      validated () {
        const fields = this.fields;
        const validated = _.every(fields, (field, f) => {
          return !this.toggle[f]||!_.has(field, 'rules')||_.every(field.rules, valid => valid(field.value)===true);
        });
        return validated;
      },

      size () {
        return this.breakpoint(null, 'xs') ? '100vw' : this.breakpoint(null, 'sm') ? '60vw' : '36rem'
      },
    },
    
    watch: {
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          if (data!==null) {
            data = _.cloneDeep(data);
            _.each(this.fields, (field, key) => {
              field.value = data[key];
            })
          }
        }
      }
    },

    methods: {
      ...services,

      setField (key, value, save=false) {
        this.fields[key].value = value;
        if (save) {
          this.edit(null);
          this.save();
        }
      },

      updateDate (key, attr, value) {
        console.log(key, attr, value);
        const fields = this.fields;
        const [date, time] = _.split((fields[key].value==null ? this.$moment().hour(20).minute(30).format('YYYY-MM-DD HH:mm:ss') : fields[key].value), ' ');
        fields[key].value = attr=='date' ? (this.$moment(value).year()<this.$moment().year() ? this.$moment(value).year(this.$moment().year()).format('YYYY-MM-DD') : value) + ' ' + time : date + ' ' + value + ':00';

        // update other dates if null
        const index = fields[key].order;
        const dates = _.orderBy(_.filter(fields, ['date', true]), ['order'], ['asc']);
        _.each(dates, (field, i) => {
          const offset = this.$moment(fields[key].value).add(i-index, 'hours').format('YYYY-MM-DD HH:mm:ss');
          if (field.value==null||key=='mobilizesAt') field.value = offset;
          // if (i>0&&this.$moment(field.value).isBefore(dates[i-1].value)) field.value = this.$moment(dates[i-1].value).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
          // if (i<dates.length-1&&this.$moment(field.value).isAfter(dates[i+1].value)) field.value = this.$moment(dates[i+1].value).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
        })
      },

      driverFilter (item, queryText) {
        return _.size(this.searchEngine([item], queryText, false, ['fullname','vehicle.model','vehicle.brand','buzzer.number','tags'], true))>0;
      },
      
      selectDriver (select, driver) {
        console.log('driver', select, driver.partner_id);
        const controller = this.fields.drivers;
        if (select) {
          controller.value.push(driver);
        }else{
          controller.value = _.reject(controller.value, ['partner_id', driver.partner_id]);
        }
        this.$nextTick(() => {
          controller.search.value = null;
          controller.search.query = null;
        })
      },

      message (b, to) {
        this.$emit('message', b, to, this.title, this.briefing, 'flashmob#'+this.data.id);
      },
      
      openDriver (id) {
        this.$emit('open-driver', id);
      },

      invite (drivers) {
        const all = this.fields.drivers.value;
        drivers = _.isNumber(drivers) ? [drivers] : _.map(_.isNil(drivers) ? all : drivers, driver => _.isObject(driver) ? driver.partner_id : driver);
        const briefing = this.briefing;
        this.$emit('invite', this.data.id, drivers, { briefing });
      },

      rsvp (partner_id) {
        this.$emit('rsvp', this.data.id, partner_id);
      },

      payment () {
        this.$emit('payment', this.data.id);
      },

      cancel () {
        this.$emit('cancel', this.data.id);
      },

      map (selected) {
        const buzzers = _.map(selected, 'cod_device');
        console.log(buzzers);
        this.$emit('open-map', buzzers);
      },

      edit (field) {
        if (field==null) {
          _.each(this.fields, field => {
            if (_.has(field, 'editing')) field.editing = false;
          });
          this.level = 0;
          return null;
        }
        switch (field) {
          case 'program':
            // 
            
          default:
            this.fields[field].editing = true;
            this.level = 1;
            break;
        }
      },

      save () {
        const fields = _.omit(_.mapValues(this.fields, 'value'), ['program']);
        fields.max = !fields.max || fields.max=='' ? null : parseInt(fields.max);
        fields.drivers = _.map(_.reject(fields.drivers, driver => driver.uninvitedAt!=null), 'partner_id');
        const program = this.fields.program.value;
        fields.program_id = _.isNil(program) ? null : program.program_id;
        this.$emit('save', fields);
      },

      close () {
        this.$emit('close');
      },
      
      alert () {
        this.$emit('alert', this.$t('messages.waiting_response'));
      },

      statusBuzzer (device) {
        let color = '⚫';
        const updated = device.dt_status_device;
        const tracked = device.dt_impression_device;
        let diff;
        if (device.status_device=='ON') {
          if (updated&&tracked) {
            const diffTrack = this.$moment(updated).diff(tracked,'minutes');
            const diffImpression = this.$moment().diff(updated,'minutes'); 
            diff = diffImpression;
            if (diff<=15) {
              color = '🟢';
            }else{
              color = '🟡';
            }
          }else{
            color = '⚫';
          }
        }else{
          color = '🔴';
        }
        return color;
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: () => { 
          return {
            id: 'new',
            title: null,
            type: 'FLS',
            active: 0,
            hoload: 0,
            mobilizesAt: null,
            startsAt: null,
            endsAt: null,
            demobilizesAt: null,
            location: null,
            meeting_point: null,
            drivers: [],
            route: null,
            reward: 100,
            program: null,
            player_config: null
          }
        }
      },
      drivers: {
        type: Object,
        default: () => {}
      },
      driverPrototype: {
        type: Object,
        default: () => {}
      },
      loading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object,
        default: () => null
      }
    },

    mounted () {
    },

    components: {
      MField: () => import('@/components/mField'),
      DriverList: () => import('@/components/DriverList'),
      MProgram: () => import('@/components/mProgram'),
      MAds: () => import('@/components/mAdDisplay'),
      MBtnLong: () => import('@/components/mBtnLong'),
      MPlayerConfig: () => import('@/components/PlayerConfig'),
    }

  }
</script>
